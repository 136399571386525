exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-condiciones-generales-js": () => import("./../../../src/pages/condiciones-generales.js" /* webpackChunkName: "component---src-pages-condiciones-generales-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-reformas-ciudad-js": () => import("./../../../src/pages/reformas-ciudad.js" /* webpackChunkName: "component---src-pages-reformas-ciudad-js" */),
  "component---src-pages-reformas-de-apartamentos-js": () => import("./../../../src/pages/reformas-de-apartamentos.js" /* webpackChunkName: "component---src-pages-reformas-de-apartamentos-js" */),
  "component---src-pages-reformas-de-banos-js": () => import("./../../../src/pages/reformas-de-banos.js" /* webpackChunkName: "component---src-pages-reformas-de-banos-js" */),
  "component---src-pages-reformas-de-chalets-js": () => import("./../../../src/pages/reformas-de-chalets.js" /* webpackChunkName: "component---src-pages-reformas-de-chalets-js" */),
  "component---src-pages-reformas-de-cocinas-js": () => import("./../../../src/pages/reformas-de-cocinas.js" /* webpackChunkName: "component---src-pages-reformas-de-cocinas-js" */),
  "component---src-pages-reformas-de-comercios-js": () => import("./../../../src/pages/reformas-de-comercios.js" /* webpackChunkName: "component---src-pages-reformas-de-comercios-js" */),
  "component---src-pages-reformas-de-comunidades-js": () => import("./../../../src/pages/reformas-de-comunidades.js" /* webpackChunkName: "component---src-pages-reformas-de-comunidades-js" */),
  "component---src-pages-reformas-js": () => import("./../../../src/pages/reformas.js" /* webpackChunkName: "component---src-pages-reformas-js" */),
  "component---src-templates-reformas-ciudad-js": () => import("./../../../src/templates/reformas-ciudad.js" /* webpackChunkName: "component---src-templates-reformas-ciudad-js" */)
}

